<template>
  <main>
    <div v-if="!loading && article" class="bg-white px-4 sm:px-6 lg:px-8">
      <div class="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h1
            class="text-3xl mb-2 leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
          >
            Cannabidiol and {{ article.title }}
          </h1>
          <h2
            class="text-2xl mb-12 leading-9 tracking-tight font-bold text-gray-900 sm:leading-10"
          >
            Studies and peer-reviewed research into the effects of CBD and
            {{ article.title }}
          </h2>

          <div
            v-for="section in article.content"
            :key="section.link"
            class="mt-3 text-xl leading-7 text-gray-500 mb-12"
          >
            <div class="my-4 break-words">
              <a
                class="block mb-1 underline text-blue-500"
                :href="section.link"
                target="_blank"
                >{{ section.linkText }}</a
              >
              <em class="font-bold">From the abstract:</em>
              <blockquote class="mt-1">
                {{ section.abstract }}
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NotFound v-if="!loading && !article" item="Article" />
  </main>
</template>

<script>
import NotFound from "../../components/NotFound.vue";

export default {
  components: {
    NotFound
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    article() {
      return this.$store.state.articles.find(
        article => article.path === this.$route.params.article
      );
    }
  },

  metaInfo() {
    return {
      title: this.article
        ? "Cannabidiol and " + this.article.title
        : "CBD Research Library",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: `Studies and peer-reviewed research into the effects of Cannabidiol (CBD) and ${
            this.article ? this.article.title : ""
          }`
        }
      ]
    };
  },

  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.fetchArticle();
  },

  // Client-side only
  mounted() {
    // If we didn't already do it on the server
    // we fetch the item (will first show the loading text)
    if (!this.article) {
      this.fetchArticle();
    } else {
      this.loading = false;
    }
  },

  methods: {
    fetchArticle() {
      return this.$store
        .dispatch("fetchArticle", this.$route.params.article)
        .then(() => (this.loading = false))
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
